@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-Extralight.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-ExtralightItalic.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-RegularItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-Extrabold.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-ExtraboldItalic.otf") format("opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-Heavy.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "Rockford Sans";
  src: url("../fonts/RockfordSans-HeavyItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Italic.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Light.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-LightItalic.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Medium.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-MediumItalic.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Regular.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-SemiBold.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-SemiBoldItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Black.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-BlackItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Bold.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-BoldItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-ExtraBold.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

/* NunitoSans Font */
@font-face {
  font-family: "NunitoSans";
  src: url("../fonts/NunitoSans-ExtraLight.ttf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "NunitoSans";
  src: url("../fonts/NunitoSans-ExtraLightItalic.ttf") format("opentype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "NunitoSans";
  src: url("../fonts/NunitoSans-Light.ttf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "NunitoSans";
  src: url("../fonts/NunitoSans-LightItalic.ttf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "NunitoSans";
  src: url("../fonts/NunitoSans-Regular.ttf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "NunitoSans";
  src: url("../fonts/NunitoSans-Medium.ttf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "NunitoSans";
  src: url("../fonts/NunitoSans-MediumItalic.ttf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "NunitoSans";
  src: url("../fonts/NunitoSans-Bold.ttf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "NunitoSans";
  src: url("../fonts/NunitoSans-BoldItalic.ttf") format("opentype");
  font-weight: 700;
  font-style: italic;
}
