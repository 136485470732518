@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("./fonts.css");

@layer mantine, mantine-datatable;

@layer base {
  html {
    @apply md:text-[16px] text-[14px] text-textPrimary leading-[1.75] font-nunitosans;
  }

  h1 {
    /* ('md: 41.056 px', 'sm: 35.924 px')*/
    @apply text-[2.566rem] font-bold;
  }

  h2 {
    /* ('md: 28.832 px', 'sm: 25.228 px')*/
    @apply text-[1.802rem] font-bold;
  }

  h3 {
    /* ('md: 25.632 px', 'sm: 22.428 px')*/
    @apply text-[1.602rem] font-bold;
  }

  h4 {
    /* ('md: 22.784 px', 'sm: 19.936 px')*/
    @apply text-[1.424rem] font-bold;
  }

  h5 {
    /* ('md: 20.256 px', 'sm: 17.724 px') */
    @apply text-[1.266rem] font-bold;
  }

  h6 {
    /* ('md: 18.0 px', 'sm: 15.75 px') */
    @apply text-[1.125rem] font-bold;
  }

  small {
    /* ('md: 14.224 px', 'sm: 12.446 px') */
    @apply text-[0.889rem] font-bold;
  }
}
* {
  transition-timing-function: linear;
  transition-duration: 25ms;
}

.modal-overlay {
  @apply absolute h-full w-full bg-gray-900 bg-opacity-80;
}

.clickable {
  @apply cursor-pointer select-none disabled:cursor-not-allowed;
}

.clickable-scale {
  @apply hover:scale-105 active:scale-100 clickable disabled:hover:scale-100;
}

.wysiwyg-content p,
#richtexteditor p {
  @apply pb-5;
}
.wysiwyg-content ul,
#richtexteditor ul {
  @apply list-disc ml-[2.5em];
}

.wysiwyg-content ol,
#richtexteditor ol {
  @apply list-decimal ml-[2.5em];
}

.wysiwyg-content h4,
#richtexteditor h4 {
  @apply font-bold text-[20px];
}

.wysiwyg-content a,
#richtexteditor a {
  @apply underline text-[#1a0dab];
}

.wysiwyg-content a:visited,
#richtexteditor a:visited {
  @apply underline text-[#681da8];
}

.wysiwyg-content code,
#richtexteditor code {
  @apply bg-[#f9f2f4] text-[85%] text-[#c7254e] py-[0.2em] px-[0.3125em] rounded-[4px];
}

.wysiwyg-content blockquote,
#richtexteditor blockquote {
  @apply border-l-4 border-[#1b1f230d] pl-[1em] py-[0.5em] my-[1em];
}

.required {
  @apply after:content-['*'] after:ml-0.5 after:text-red-500;
}

.icon-menu-dropdown {
  @apply bg-white rounded-[10px] clickable-scale text-black h-[46px] w-[46px] flex items-center justify-center;
}

iframe {
  @apply h-[326px] w-full;
}

.checkboxes {
  margin-top: 2rem;
}
/* Checkbox styles */
.cstmlchckbx {
  margin-top: 0.5rem;
  padding-left: 1.75rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cstmlchckbx input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
.cstmlchckbx input[type="checkbox"]:focus ~ span {
  border: 2px solid #aab0b9;
}
.cstmlchckbx input[type="checkbox"]:focus:checked ~ span {
  border: 2px solid #20644c;
}
.cstmlchckbx input[type="checkbox"]:checked ~ span {
  color: #fff;
  background: #329e78
    url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+)
    50% 40% no-repeat;
  border: 2px solid #329e78;
}
.cstmlchckbx span {
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: -2px;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #d4d7dc;
  border: 2px solid #d4d7dc;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
